import { EmailTemplateType } from 'shared/models/email-templates'
import {
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Underline,
  Heading,
  Link,
  List,
  Image,
  ImageInsertViaUrl,
  AutoImage,
  ImageToolbar,
  ImageCaption,
  ImageTextAlternative,
  ImageResizeEditing,
  ImageResizeHandles,
  LinkImage,
  ImageResize,
  ImageStyle
} from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'

const emailTypeNames: Record<EmailTemplateType, string> = {
  ReservationCancel: 'Отмена бронирования',
  ReservationCreate: 'Создание бронирования'
}

const emailTypeColors: Record<EmailTemplateType, string> = {
  ReservationCancel: '#f44336',
  ReservationCreate: '#4caf50'
}

const imageConfiguration = {
  resizeOptions: [
    {
      name: 'resizeImage:original',
      value: null,
      label: 'Original'
    },
    {
      name: 'resizeImage:custom',
      label: 'Custom',
      value: 'custom'
    },
    {
      name: 'resizeImage:40',
      value: '40',
      label: '40%'
    },
    {
      name: 'resizeImage:60',
      value: '60',
      label: '60%'
    }
  ],
  toolbar: [
    'linkImage',
    '|',
    'imageTextAlternative',
    'toggleImageCaption',
    '|',
    'imageStyle:inline',
    'imageStyle:wrapText',
    'imageStyle:breakText',
    '|',
    'resizeImage'
  ]
}

const editorConfig = {
  toolbar: {
    items: [
      'heading',
      '|',
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'link',
      'imageInsert'
    ]
  },
  image: imageConfiguration,
  // image: {
  //   toolbar: ['toggleImageCaption', 'imageTextAlternative', 'ckboxImageEdit']
  // },
  plugins: [
    Heading,
    Bold,
    Essentials,
    Italic,
    Mention,
    Paragraph,
    Undo,
    Underline,
    Link,
    List,
    Image,
    AutoImage,
    ImageInsertViaUrl,
    ImageToolbar,
    ImageCaption,
    ImageTextAlternative,
    LinkImage,
    ImageResize,
    ImageResizeEditing,
    ImageResizeHandles,
    ImageStyle
  ]
}

export { emailTypeNames, emailTypeColors, editorConfig }
