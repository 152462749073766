import { OrderStatus, PaymentStatus } from 'shared/models'

const paymentStatusText: Record<PaymentStatus, string> = {
  Failed: 'Отклонено',
  None: '',
  Paid: 'Предоплачено',
  Requested: 'Запрошено'
}

const orderStatusText: Record<OrderStatus, string> = {
  Created: 'Создан',
  Cancelled: 'Отменен',
  Confirmed: 'Подтвержден',
  Completed: 'Завершен'
}

const orderStatusColor: Record<OrderStatus, string> = {
  Cancelled: '#fff',
  Confirmed: '#fff',
  Created: '#000',
  Completed: '#fff'
}

const orderStatusBackground: Record<OrderStatus, string> = {
  Cancelled: '#e01b1b',
  Confirmed: '#11b511',
  Created: '#e7e7e7',
  Completed: '#5667f4'
}

export { paymentStatusText, orderStatusText, orderStatusBackground, orderStatusColor }
