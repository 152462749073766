import {
  ServiceModel,
  ServiceCreateModel,
  ServicePriceModel,
  ServicePriceCreateModel,
  ServiceScheduleModel,
  ServiceScheduleCreateModel
} from 'shared/models'
import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'

const service = {
  getServiceList(config?: AxiosRequestConfig) {
    return adminInstance.get<ServiceModel[]>('/services', { ...config })
  },

  getService(serviceId: number, config?: AxiosRequestConfig) {
    return adminInstance.get<ServiceModel>(`/services/${serviceId}`, config)
  },

  createService(data: ServiceCreateModel, config?: AxiosRequestConfig) {
    return adminInstance.post<void>('/services', data, config)
  },

  updateService(serviceId: number, data: ServiceModel, config?: AxiosRequestConfig) {
    return adminInstance.put<void>(`/services/${serviceId}`, data, config)
  },

  deleteService(serviceId: number, config?: AxiosRequestConfig) {
    return adminInstance.delete<void>(`/services/${serviceId}`, config)
  },

  generateTimeslots(serviceId: number, config?: AxiosRequestConfig) {
    return adminInstance.post<void>(`/services/${serviceId}/generateTimeslots`, config)
  },

  getServicePriceList(serviceId: number, config?: AxiosRequestConfig) {
    return adminInstance.get<ServicePriceModel[]>(`/prices`, {
      params: { serviceId },
      paramsSerializer: { indexes: null },
      ...config
    })
  },

  getServicePrice(priceId: number, config?: AxiosRequestConfig) {
    return adminInstance.get<ServicePriceModel>(`/prices/${priceId}`, { ...config })
  },

  createServicePrice(data: ServicePriceCreateModel, config?: AxiosRequestConfig) {
    return adminInstance.post<void>(`/prices`, data, config)
  },

  updateServicePrice(priceId: number, data: ServicePriceModel, config?: AxiosRequestConfig) {
    return adminInstance.put<void>(`/prices/${priceId}`, data, config)
  },

  deleteServicePrice(priceId: number, config?: AxiosRequestConfig) {
    return adminInstance.delete<void>(`/prices/${priceId}`, config)
  },

  getServiceScheduleList(serviceId: number, config?: AxiosRequestConfig) {
    return adminInstance.get<ServiceScheduleModel[]>(`/schedules`, { params: { serviceId }, ...config })
  },

  getServiceSchedule(scheduleId: number, config?: AxiosRequestConfig) {
    return adminInstance.get<ServiceScheduleModel>(`/schedules/${scheduleId}`, { ...config })
  },

  createServiceSchedule(data: ServiceScheduleCreateModel, config?: AxiosRequestConfig) {
    return adminInstance.post<void>(`/schedules`, data, config)
  },

  updateServiceSchedule(scheduleId: number, data: ServiceScheduleModel, config?: AxiosRequestConfig) {
    return adminInstance.put<void>(`/schedules/${scheduleId}`, data, config)
  },

  deleteServiceSchedule(scheduleId: number, config?: AxiosRequestConfig) {
    return adminInstance.delete<void>(`/schedules/${scheduleId}`, config)
  }
}

export { service }
