import { Controller, useForm } from 'react-hook-form'
import { QuestEditFormProps } from './types'
import { QuestCreateModel, QuestModel } from 'shared/models'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Tab,
  TextField,
  Typography
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidations } from './validation'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useState } from 'react'
import { questCreateData } from './utils'
import { FileUploadButton } from 'features/file-upload-button'
import { GalleryTab } from 'features/image-gallery/gallery-tab'
import { AdditionalServiceList } from 'features/additional-service-list'

function QuestEditForm({
  defaultData,
  onSubmit,
  isProcess,
  isEdit,
  addressList,
  categories,
  serviceList,
  onDelete
}: QuestEditFormProps) {
  const [isDeleting, setIsDeleting] = useState(false)

  const [tab, setTab] = useState<'main' | 'seo' | 'additionalServices' | 'gallery'>('main')

  const { control, handleSubmit, watch, setValue } = useForm<QuestModel | QuestCreateModel>({
    defaultValues: defaultData || questCreateData,
    disabled: isProcess,
    resolver: yupResolver<QuestModel | QuestCreateModel>(getValidations())
  })

  function handleDelete() {
    setIsDeleting(true)
  }

  function cancelDelete() {
    setIsDeleting(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card elevation={1}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={(_, value) => setTab(value)}>
              <Tab label="Основная информация" value="main" />
              {!!isEdit && <Tab label="Дополнительные услуги" value="additionalServices" />}
              <Tab label="SEO" value="seo" />
              <Tab label="Галерея" value="gallery" />
            </TabList>
          </Box>
          <TabPanel value="main">
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Название"
                  fullWidth
                  autoComplete="off"
                  placeholder="Название"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="none"
                />
              )}
            />
            <Controller
              control={control}
              name="addressId"
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard" margin="dense">
                  <InputLabel>Адрес</InputLabel>
                  <Select
                    value={field.value || ''}
                    onChange={(e) => {
                      field.onChange(e)
                      setValue('mainServiceId', undefined as any)
                    }}
                    error={!!fieldState.error}
                    fullWidth
                    disabled={isProcess || isEdit}
                  >
                    {addressList?.map?.((address) => (
                      <MenuItem key={address.id} value={address.id}>
                        {[address.city, address.address].filter(Boolean).join(', ')}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="mainServiceId"
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard" margin="dense">
                  <Autocomplete
                    value={serviceList?.find?.((s) => s.id === field.value) || null}
                    fullWidth
                    onChange={(_, value) => field.onChange(value?.id)}
                    options={
                      serviceList
                        ?.filter?.((s) => s.addressId === watch('addressId'))
                        ?.filter?.((s) => !!s.withReservation) || []
                    }
                    clearOnEscape={false}
                    clearIcon={false}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Основная услуга"
                        margin="dense"
                        variant="standard"
                        size="small"
                        error={!!fieldState.error}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            {!!watch('mainServiceId') && (
              <Controller
                control={control}
                name="duration"
                defaultValue={undefined}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Длительность"
                    autoComplete="off"
                    placeholder="Длительность услуги"
                    variant="standard"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={field.value ?? ''}
                    onChange={(ev) => field.onChange(ev.target.value ? Number(ev.target.value) : null)}
                    onBlur={field.onBlur}
                    disabled={isProcess}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    ref={field.ref}
                    margin="dense"
                  />
                )}
              />
            )}
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <Controller
                control={control}
                name="minPersons"
                defaultValue={undefined}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Минимальное количество участников"
                    autoComplete="off"
                    placeholder="Минимальное количество участников"
                    variant="standard"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={field.value ?? ''}
                    onChange={(ev) => field.onChange(ev.target.value ? Number(ev.target.value) : null)}
                    onBlur={field.onBlur}
                    disabled={isProcess}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    ref={field.ref}
                    margin="dense"
                  />
                )}
              />
              <Controller
                control={control}
                name="maxPersons"
                defaultValue={undefined}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Максимальное количество участников"
                    autoComplete="off"
                    placeholder="Максимальное количество участников"
                    variant="standard"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={field.value ?? ''}
                    onChange={(ev) => field.onChange(ev.target.value ? Number(ev.target.value) : null)}
                    onBlur={field.onBlur}
                    disabled={isProcess}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    ref={field.ref}
                    margin="dense"
                  />
                )}
              />
            </div>
            <Controller
              control={control}
              name="categories"
              defaultValue={[]}
              render={({ field, fieldState }) => (
                <Autocomplete
                  multiple
                  size="small"
                  options={categories || []}
                  getOptionLabel={(option) => option.name}
                  value={(field.value || []).map(
                    (el) => categories?.find?.((c) => c.id === el) || { id: el, name: '' }
                  )}
                  filterSelectedOptions
                  disabled={isProcess}
                  onChange={(_, value) => field.onChange(value.map((el) => el.id))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Категории"
                      placeholder="Категории"
                      error={!!fieldState.error}
                      margin="dense"
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Описание"
                  multiline
                  fullWidth
                  autoComplete="off"
                  placeholder="Описание"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'baseline' }}>
              <Controller
                control={control}
                name="previewImageUrl"
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    label="URL превью изображения"
                    fullWidth
                    autoComplete="off"
                    placeholder="URL превью изображения"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isProcess}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    ref={field.ref}
                    margin="dense"
                  />
                )}
              />
              <FileUploadButton
                onFileChange={(url) => setValue('previewImageUrl', url)}
                imageMaxWidthOrHeight={600}
                imageMaxSizeMB={0.1}
              />
            </div>
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'baseline' }}>
              <Controller
                control={control}
                name="headerImageUrl"
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    label="URL изображения на странице квеста"
                    fullWidth
                    autoComplete="off"
                    placeholder="URL изображения на странице квеста"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isProcess}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    ref={field.ref}
                    margin="dense"
                  />
                )}
              />
              <FileUploadButton
                imageMaxWidthOrHeight={1920}
                imageMaxSizeMB={0.5}
                onFileChange={(url) => setValue('headerImageUrl', url)}
              />
            </div>
            <Controller
              control={control}
              name="ageRating"
              defaultValue={0}
              render={({ field, fieldState }) => (
                <TextField
                  label="Возрастной рейтинг"
                  autoComplete="off"
                  placeholder="Возрастной рейтинг"
                  variant="standard"
                  type="number"
                  style={{ width: '130px' }}
                  InputLabelProps={{ shrink: true }}
                  value={field.value ?? ''}
                  onChange={(ev) => field.onChange(ev.target.value ? Number(ev.target.value) : null)}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  InputProps={{ endAdornment: '+' }}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="complexity"
              defaultValue={0}
              render={({ field }) => (
                <Box sx={{ margin: '0.5rem 0' }}>
                  <Typography component="legend" variant="caption" color="GrayText">
                    Сложность
                  </Typography>
                  <Rating
                    value={field.value}
                    onChange={(_, newValue) => {
                      field.onChange(newValue)
                    }}
                  />
                </Box>
              )}
            />
          </TabPanel>
          <TabPanel value="seo">
            <Controller
              control={control}
              name="htmlTitle"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="HTML Заголовок"
                  fullWidth
                  autoComplete="off"
                  placeholder="HTML Заголовок"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="none"
                />
              )}
            />
            <Controller
              control={control}
              name="htmlKeywords"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="HTML Ключевые слова"
                  multiline
                  fullWidth
                  autoComplete="off"
                  placeholder="HTML Ключевые слова"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="htmlDescription"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="HTML Описание"
                  multiline
                  fullWidth
                  autoComplete="off"
                  placeholder="HTML Описание"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
          </TabPanel>
          <TabPanel value="additionalServices" style={{ padding: 0 }}>
            <Controller
              control={control}
              name="additionalServiceIds"
              defaultValue={[]}
              render={({ field }) => (
                <AdditionalServiceList
                  addressId={defaultData?.addressId}
                  onChange={field.onChange}
                  value={field.value}
                  serviceList={serviceList}
                />
              )}
            />
          </TabPanel>
          <TabPanel value="gallery">
            <GalleryTab images={watch('gallery') || []} onChange={(photos) => setValue('gallery', photos)} />
          </TabPanel>
        </TabContext>
      </Card>
      {!isDeleting && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: '1rem' }}>
          {isEdit && (
            <Button onClick={handleDelete} disabled={isProcess}>
              Удалить
            </Button>
          )}
          <Button type="submit" variant="outlined" disabled={isProcess}>
            Сохранить
          </Button>
        </Box>
      )}
      {isDeleting && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 3, gap: '1rem' }}>
          Вы действительно хотите удалить квест?
          <Button onClick={cancelDelete} disabled={isProcess}>
            Отмена
          </Button>
          <Button variant="outlined" color="error" disabled={isProcess} onClick={onDelete}>
            Удалить
          </Button>
        </Box>
      )}
    </form>
  )
}

export { QuestEditForm }
