import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  AppBar,
  Badge,
  Menu,
  Button,
  LinearProgress,
  Dialog,
  DialogTitle,
  Tooltip
} from '@mui/material'
import FiltersIcon from '@mui/icons-material/FilterList'
import { FilterMenuWrapper, SchedulerContainer, SchedulerWrapper } from './styled'
import { OrderStatus } from 'shared/models'
import { useQuery } from 'react-query'
import { adminService } from 'shared/api'
import { useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DatePicker } from 'features/date'
import { Scheduler } from './components'
import { SchedulePosition } from './scheduler-base'
import { SchedulerOrder } from './components/scheduler-order'
import { WeekObject } from 'features/date/types'
import dayjs from 'dayjs'
import CreateIcon from '@mui/icons-material/Add'
import { OrderEditForm } from 'pages/orders/order-edit-form'
import { useAppSelector } from 'store'
import { getMenuOpenSelector } from 'store/slices/menu-slice'
import { useMediaQuery } from 'react-responsive'
import { SearchInput } from 'features/search-input'

function ReservationSchedulerPage({ weekdaysMode }: { weekdaysMode?: boolean }) {
  const addressKey = weekdaysMode ? 'SCHEDULER_WEEKDAY_address' : 'SCHEDULER_address'
  const serviceKey = weekdaysMode ? 'SCHEDULER_WEEKDAY_service' : 'SCHEDULER_service'
  const statusKey = weekdaysMode ? 'SCHEDULER_WEEKDAY_status' : 'SCHEDULER_status'
  const weekDaysServiceKey = weekdaysMode ? 'SCHEDULER_WEEKDAY_weekDaysService' : 'SCHEDULER_weekDaysService'

  const [searchParams, setSearchParams] = useSearchParams()

  const { data: addressData } = useQuery(['addressList'], ({ signal }) =>
    adminService.address.getAddressList({ signal })
  )

  const { data: servicesData } = useQuery(['serviceList'], ({ signal }) =>
    adminService.service.getServiceList({ signal })
  )

  const menuOpened = useAppSelector(getMenuOpenSelector)
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  const dateQuery = searchParams.get('date')
  const today = new Date().setUTCHours(0, 0, 0, 0)

  const initDate = dateQuery ? Number(dateQuery) : today
  const startWeek = dayjs(initDate, { utc: true }).startOf('week').toDate().getTime()
  const endWeek = dayjs(initDate, { utc: true }).endOf('week').toDate().getTime()

  const [date, setDate] = useState<number>(initDate)

  const [startDate, setStartDate] = useState<number>(startWeek)
  const [endDate, setEndDate] = useState<number>(endWeek)

  const [filtersMenu, setFiltersMenu] = useState<HTMLElement | null>(null)
  const [term, setTerm] = useState(searchParams.get('term') || '')
  const [addressId, setAddressId] = useState<string | undefined>(
    searchParams.get('address') || sessionStorage.getItem(addressKey) || undefined
  )
  const [service, setService] = useState<string[]>(
    (searchParams.get('service')?.split(',') as string[]) ||
      (sessionStorage.getItem(serviceKey)?.split(',') as OrderStatus[]) ||
      []
  )
  const [status, setStatus] = useState<OrderStatus[]>(
    (searchParams.get('status')?.split(',') as OrderStatus[]) ||
      (sessionStorage.getItem(statusKey)?.split(',') as OrderStatus[]) ||
      []
  )
  const [weekDaysService, setWeekDaysService] = useState<string | undefined>(
    searchParams.get('weekDaysService') || sessionStorage.getItem(weekDaysServiceKey) || undefined
  )

  useEffect(() => {
    const search = new URLSearchParams(searchParams)

    if (term) {
      search.set('term', term)
    } else {
      search.delete('term')
    }

    if (addressId) {
      sessionStorage.setItem(addressKey, String(addressId || ''))
      search.set('address', String(addressId))
    } else {
      sessionStorage.removeItem(addressKey)
      search.delete('address')
    }

    if (service.length) {
      sessionStorage.setItem(serviceKey, String(service.join(',')))
      search.set('service', service.join(','))
    } else {
      sessionStorage.removeItem(serviceKey)
      search.delete('service')
    }

    if (status.length) {
      sessionStorage.setItem(statusKey, String(status.join(',')))
      search.set('status', status.join(','))
    } else {
      sessionStorage.removeItem(statusKey)
      search.delete('status')
    }

    if (weekDaysService) {
      sessionStorage.setItem(weekDaysServiceKey, String(weekDaysService))
      search.set('weekDaysService', weekDaysService)
    } else {
      sessionStorage.removeItem(weekDaysServiceKey)
      search.delete('weekDaysService')
    }

    setSearchParams(search)
  }, [addressId, service, status, weekDaysService, term])

  const [selectedOrderId, setSelectedOrderId] = useState<string | number | null>(null)

  const [orderCreateDialogOpen, setOrderCreateDialogOpen] = useState(false)

  const statusForFilter: OrderStatus[] = status?.length ? status : ['Created', 'Confirmed']

  const { data, isLoading, refetch } = useQuery(
    weekdaysMode ? ['reservationScheduler', startDate, endDate, weekDaysService] : ['reservationScheduler', date],
    ({ signal }) =>
      adminService.order.getSchedulerOrders(
        weekdaysMode
          ? { startDate, endDate, service: weekDaysService ? [weekDaysService] : [] }
          : { startDate: date, endDate: date + 24 * 60 * 60 * 1000 },
        { signal }
      ),
    { enabled: weekdaysMode ? !!startDate && !!endDate && !!weekDaysService : !!date }
  )

  function handleRefetch() {
    refetch()
  }

  function handleTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTerm(e.target.value)
  }

  function handleDateChange(date: number | null) {
    const search = new URLSearchParams(searchParams)
    if (!!date && date !== today) {
      search.set('date', String(date))
    } else {
      search.delete('date')
    }
    setSearchParams(search)

    setDate(date || today)
  }

  function handleWeekChange(val: WeekObject) {
    const search = new URLSearchParams(searchParams)

    const date = val.selectedDate

    if (!!date && date !== today) {
      search.set('date', String(date))
    } else {
      search.delete('date')
    }
    setSearchParams(search)

    setDate(date || today)

    setStartDate(val.startDate)
    setEndDate(val.endDate)
  }

  function openFilters(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setFiltersMenu(ev.currentTarget)
  }

  function closeFilters() {
    setFiltersMenu(null)
  }

  function handleClearFilters() {
    setAddressId(undefined)
    setService([])
    setStatus([])
    const search = new URLSearchParams(searchParams)
    search.delete('service')
    search.delete('status')
    search.delete('address')
    setSearchParams(search)
  }

  const filteredServices = useMemo(() => {
    return (servicesData?.data || []).filter((s) => {
      if (!s.withReservation) {
        return false
      }

      if (!!addressId && String(s.addressId) !== String(addressId)) {
        return false
      }

      if (service.length > 0 && !service.includes(String(s.id))) {
        return false
      }

      return true
    })
  }, [servicesData?.data, addressId, service])

  const schedulePositions: SchedulePosition[] = (data?.data || [])
    .filter((order) => {
      if (statusForFilter.length > 0 && !statusForFilter.includes(order.status)) {
        return false
      }

      if (!!addressId && String(order?.mainService?.service?.addressId) !== String(addressId)) {
        return false
      }

      if (service.length > 0 && !service.includes(String(order.mainService?.service?.id))) {
        return false
      }

      const termForFilter = [
        order.customer?.name,
        order.customer?.phoneNumber,
        order.customer.phoneNumber?.replace(/\D/g, ''),
        order.customer?.email
      ]
        .join(' ')
        .toLowerCase()

      if (term && !termForFilter.includes(term.toLowerCase())) {
        return false
      }

      return true
    })
    .map((order) => ({
      id: order.id,
      text: order.mainService?.service?.name || '',
      resourceId: order.mainService?.service?.id,
      rawPosition: order,
      startDate: order.mainService?.reservation?.startTime || 0,
      endDate: order.mainService?.reservation?.endTime || 0
    }))

  const schedulerWidth = useMemo(() => {
    if (weekdaysMode) {
      return document.documentElement.clientWidth < 896 ? 896 : document.documentElement.clientWidth
    }

    if (filteredServices.length * 100 < document.documentElement.clientWidth) {
      return document.documentElement.clientWidth
    } else {
      return filteredServices.length * 100
    }
  }, [filteredServices])

  const selectedPositionChange = useMemo(() => {
    return (id: string | number | null) => {
      setSelectedOrderId((curr) => {
        if (curr === id) {
          return null
        }

        return id
      })
    }
  }, [])

  function handleCreate() {
    setOrderCreateDialogOpen(true)
  }

  function closeOrderCreateDialog() {
    setOrderCreateDialogOpen(false)
    refetch()
  }

  const handleOrderClose = useCallback(() => {
    setSelectedOrderId(null)
  }, [])

  const selectedOrder = (data?.data || []).find((position) => String(position.id) === String(selectedOrderId))

  return (
    <>
      <AppBar position="sticky" color="inherit" style={{ margin: '-1.5rem -1.5rem 0', width: 'auto' }} elevation={1}>
        <Toolbar style={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="Создать новый заказ">
            <IconButton size="small" onClick={handleCreate}>
              <CreateIcon />
            </IconButton>
          </Tooltip>
          {!!weekdaysMode && <DatePicker weekMode={!!weekdaysMode} value={date} onChange={handleWeekChange} />}
          {!!weekdaysMode && servicesData?.data?.length && (
            <Select
              style={{ minWidth: '200px', maxWidth: '200px' }}
              variant="standard"
              value={weekDaysService || ''}
              onChange={(ev) => {
                if (ev.target.value) {
                  setWeekDaysService(ev.target.value)
                } else {
                  setWeekDaysService(undefined)
                }
              }}
            >
              {servicesData?.data
                ?.filter((s) => !!s.withReservation)
                ?.filter((s) => !addressId || String(s.addressId) === String(addressId))
                ?.map?.((service) => (
                  <MenuItem key={service.id} value={String(service.id)}>
                    {service.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          {!weekdaysMode && <DatePicker value={date} onChange={handleDateChange} />}
          <SearchInput value={term} onChange={handleTermChange} onClear={() => setTerm('')} withoutLabel={true} />
          <Tooltip title="Фильтры">
            <Badge
              badgeContent={Number(!!addressId) + Number(!!service.length) + Number(!!status.length)}
              color="primary"
            >
              <IconButton onClick={openFilters} size="small">
                <FiltersIcon />
              </IconButton>
            </Badge>
          </Tooltip>
          <Menu open={!!filtersMenu} anchorEl={filtersMenu} onClose={closeFilters} elevation={2}>
            <FilterMenuWrapper>
              {!weekdaysMode && (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink>Адрес</InputLabel>
                  <Select
                    value={!!addressId && !Number.isNaN(addressId) ? addressId : -1}
                    onChange={(ev) => {
                      if (ev.target.value !== '-1') {
                        setAddressId(String(ev.target.value))
                        setService((s) => {
                          const availableServices =
                            servicesData?.data.filter((el) => String(el.addressId) === String(ev.target.value)) || []

                          const selectedServices = s.filter((el) =>
                            availableServices.map((s) => String(s.id)).includes(el)
                          )

                          return selectedServices
                        })
                      } else {
                        setAddressId(undefined)
                      }
                    }}
                  >
                    <MenuItem value="-1">Все адреса</MenuItem>
                    {addressData?.data?.map?.((address) => (
                      <MenuItem key={address.id} value={String(address.id)}>
                        {address.address}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {!weekdaysMode && (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink>Услуга</InputLabel>
                  <Select
                    multiple
                    value={service}
                    onChange={(ev) => {
                      if (ev.target.value?.length > 0) {
                        setService(ev.target.value as string[])
                      } else {
                        setService([])
                      }
                    }}
                  >
                    {servicesData?.data
                      ?.filter((s) => !!s.withReservation)
                      ?.filter((s) => !addressId || String(s.addressId) === String(addressId))
                      ?.map?.((service) => (
                        <MenuItem key={service.id} value={String(service.id)}>
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              <FormControl fullWidth variant="standard">
                <InputLabel shrink>Статус</InputLabel>
                <Select
                  multiple
                  value={status}
                  onChange={(ev) => {
                    if (ev.target.value?.length > 0) {
                      setStatus(ev.target.value as OrderStatus[])
                    } else {
                      setStatus([])
                    }
                  }}
                >
                  <MenuItem key="Created" value={'Created'}>
                    Создан
                  </MenuItem>
                  <MenuItem key="Confirmed" value={'Confirmed'}>
                    Подтвержден
                  </MenuItem>
                  <MenuItem key="Completed" value={'Completed'}>
                    Завершен
                  </MenuItem>
                </Select>
              </FormControl>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  disableElevation
                  size="small"
                  disabled={!addressId && !service.length && !status.length}
                  onClick={handleClearFilters}
                >
                  Сбросить
                </Button>
              </div>
            </FilterMenuWrapper>
          </Menu>
        </Toolbar>
        <div style={{ height: '4px' }}>{isLoading && <LinearProgress />}</div>
      </AppBar>
      <div style={{ margin: '0px -1.5rem -1.5rem', top: '4rem', width: 'auto' }}>
        <SchedulerWrapper>
          {!!servicesData?.data?.length && (
            <SchedulerContainer>
              <div style={{ width: `${schedulerWidth - (isMobile ? 0 : menuOpened ? 220 : 64)}px` }}>
                <Scheduler
                  resounces={weekdaysMode ? undefined : filteredServices}
                  positions={schedulePositions}
                  setSelectedPosition={selectedPositionChange}
                  appointmentHeight={40}
                  startDayHour={5}
                  endDayHour={23}
                  cellDuration={30}
                  date={date}
                  currentView={weekdaysMode ? 'week' : 'day'}
                />
              </div>
            </SchedulerContainer>
          )}
          {!!selectedOrder && (
            <SchedulerOrder order={selectedOrder} onClose={handleOrderClose} onRefetch={handleRefetch} />
          )}
        </SchedulerWrapper>
      </div>
      <Dialog open={orderCreateDialogOpen} onClose={closeOrderCreateDialog} fullWidth>
        <DialogTitle>Создание заказа</DialogTitle>
        <OrderEditForm onClose={closeOrderCreateDialog} />
      </Dialog>
    </>
  )
}

export { ReservationSchedulerPage }
