import { http, HttpResponse, delay } from 'msw'
import { fakeServices } from '../fake-data/fake-services'
import { fakePriceList } from '../fake-data/fake-price-list'
import { fakeServiceScheduleList } from '../fake-data/fake-service-schedule'

export const services = [
  http.get('/api/services', async () => {
    await delay(900)

    return HttpResponse.json(fakeServices)
  }),

  http.post('/api/services/:id/generateTimeslots', async () => {
    await delay(1200)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.get('/api/services/:id', async ({ params }) => {
    await delay(300)

    const { id } = params

    const foundService = id ? fakeServices.find((service) => service.id === Number(id)) : undefined

    if (!foundService) {
      return HttpResponse.json(undefined, { status: 404 })
    } else {
      return HttpResponse.json(foundService)
    }
  }),

  http.post('/api/services', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.put('/api/services/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.delete('/api/services/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.get('/api/prices', async () => {
    await delay(300)

    return HttpResponse.json(fakePriceList, { status: 200 })
  }),

  http.get('/api/prices/:id', async ({ params }) => {
    await delay(300)

    const { id } = params

    const foundPrice = id ? fakePriceList.find((price) => price.id === Number(id)) : undefined

    if (!foundPrice) {
      return HttpResponse.json(undefined, { status: 404 })
    } else {
      return HttpResponse.json(foundPrice)
    }
  }),

  http.post('/api/prices', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.put('/api/prices/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.delete('/api/prices/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.get('/api/schedules', async () => {
    await delay(300)

    return HttpResponse.json(fakeServiceScheduleList, { status: 200 })
  }),

  http.get('/api/schedules/:id', async ({ params }) => {
    await delay(300)

    const { id } = params

    const foundPrice = id ? fakeServiceScheduleList.find((schedule) => schedule.id === Number(id)) : undefined

    if (!foundPrice) {
      return HttpResponse.json(undefined, { status: 404 })
    } else {
      return HttpResponse.json(foundPrice)
    }
  }),

  http.post('/api/schedules', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.put('/api/schedules/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.delete('/api/schedules/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  })
]
