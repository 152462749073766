import {
  Box,
  Chip,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link as MuiLink,
  Toolbar,
  AppBar,
  Badge,
  Menu,
  Button,
  Skeleton,
  Dialog,
  DialogTitle,
  Tooltip,
  Divider
} from '@mui/material'
import { useQuery } from 'react-query'
import { adminService } from 'shared/api'
import { NavLink, useSearchParams } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import { orderStatusBackground, orderStatusColor, orderStatusText, paymentStatusText } from './status-utils'
import ViewIcon from '@mui/icons-material/EditNote'
import CreateIcon from '@mui/icons-material/Add'
import FiltersIcon from '@mui/icons-material/FilterList'
import { orderView } from 'pages/nav'
import { OrderStatus } from 'shared/models'
import { pluralizationRu } from 'shared/utils/pluralization-ru'
import { FilterMenuWrapper } from './styled'
import { OrderEditForm } from './order-edit-form'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { DatePicker } from 'features/date'
import { SearchInput } from 'features/search-input'
import { useMediaQuery } from 'react-responsive'

function OrderList({ cancelledOnly }: { cancelledOnly?: boolean }) {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  const addressKey = cancelledOnly ? 'ORDERLIST_CANCELLED_address' : 'ORDERLIST_address'
  const serviceKey = cancelledOnly ? 'ORDERLIST_CANCELLED_service' : 'ORDERLIST_service'
  const statusKey = cancelledOnly ? 'ORDERLIST_CANCELLED_status' : 'ORDERLIST_status'

  const [searchParams, setSearchParams] = useSearchParams()

  const [filtersMenu, setFiltersMenu] = useState<HTMLElement | null>(null)
  const [startDate, setStartDate] = useState<number | undefined>(
    searchParams.get('startDate') ? Number(searchParams.get('startDate')) : undefined
  )
  const [endDate, setEndDate] = useState<number | undefined>(
    searchParams.get('endDate') ? Number(searchParams.get('endDate')) : undefined
  )
  const [term, setTerm] = useState(searchParams.get('term') || '')
  const [addressId, setAddressId] = useState<string | undefined>(
    searchParams.get('address') || sessionStorage.getItem(addressKey) || undefined
  )
  const [service, setService] = useState<string[]>(
    (searchParams.get('service')?.split(',') as OrderStatus[]) ||
      (sessionStorage.getItem(serviceKey)?.split(',') as OrderStatus[]) ||
      []
  )
  const [status, setStatus] = useState<OrderStatus[]>(
    (searchParams.get('status')?.split(',') as OrderStatus[]) ||
      (sessionStorage.getItem(statusKey)?.split(',') as OrderStatus[]) ||
      []
  )
  const [page, setPage] = useState(searchParams.get('page') ? Number(searchParams.get('page')) : 1)
  const [pageCount, setPageCount] = useState(1)

  useEffect(() => {
    const search = new URLSearchParams(searchParams)

    if (term) {
      search.set('term', term)
    } else {
      search.delete('term')
    }

    if (startDate) {
      search.set('startDate', String(startDate))
    } else {
      search.delete('startDate')
    }

    if (endDate) {
      search.set('endDate', String(endDate))
    } else {
      search.delete('endDate')
    }

    if (addressId) {
      sessionStorage.setItem(addressKey, String(addressId || ''))
      search.set('address', String(addressId))
    } else {
      sessionStorage.removeItem(addressKey)
      search.delete('address')
    }

    if (service.length) {
      sessionStorage.setItem(serviceKey, String(service.join(',')))
      search.set('service', service.join(','))
    } else {
      sessionStorage.removeItem(serviceKey)
      search.delete('service')
    }

    if (!cancelledOnly) {
      if (status.length) {
        sessionStorage.setItem(statusKey, String(status.join(',')))
        search.set('status', status.join(','))
      } else {
        sessionStorage.removeItem(statusKey)
        search.delete('status')
      }
    }

    setSearchParams(search)
  }, [addressId, service, status, term, startDate, endDate])

  const [orderCreateDialogOpen, setOrderCreateDialogOpen] = useState(false)

  const statusForRequest: OrderStatus[] = cancelledOnly
    ? ['Cancelled']
    : status?.length
      ? status
      : ['Created', 'Confirmed']

  const { data, isLoading, refetch } = useQuery(
    ['orderList', startDate, endDate, page, term, addressId, statusForRequest, service],
    ({ signal }) =>
      adminService.order.getOrderList(
        { startDate, endDate, term, page, limit: 100, addressId, status: statusForRequest, service },
        { signal }
      ),
    {
      onSuccess: (data) => {
        setPageCount(data.data?.pageCount || 1)
      }
    }
  )

  const { data: addressData, isLoading: addressIsLoading } = useQuery(['addressList'], ({ signal }) =>
    adminService.address.getAddressList({ signal })
  )

  const { data: servicesData, isLoading: servicesIsLoading } = useQuery(['serviceList'], ({ signal }) =>
    adminService.service.getServiceList({ signal })
  )

  function handlePageChange(page: number) {
    setPage(page)
    const search = new URLSearchParams(searchParams)
    search.set('page', String(page))
    setSearchParams(search)
  }

  function handleTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTerm(e.target.value)
    setPage(1)
  }

  function openFilters(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setFiltersMenu(ev.currentTarget)
  }

  function closeFilters() {
    setFiltersMenu(null)
  }

  function handleClearFilters() {
    setAddressId(undefined)
    setService([])
    setStatus([])
    setTerm('')
    setStartDate(undefined)
    setEndDate(undefined)
  }

  function handleCreate() {
    setOrderCreateDialogOpen(true)
  }

  function closeOrderCreateDialog() {
    setOrderCreateDialogOpen(false)
    refetch()
  }

  function handleStartDateChange(date: number | null) {
    setStartDate(date || undefined)

    if (endDate && date && date > endDate) {
      setEndDate(undefined)
    }
  }

  function handleEndDateChange(date: number | null) {
    const search = new URLSearchParams(searchParams)
    if (date) {
      search.set('endDate', String(date))
    } else {
      search.delete('endDate')
    }
    setSearchParams(search)

    setEndDate(date || undefined)

    if (startDate && date && date < startDate) {
      setStartDate(undefined)
    }
  }

  const filterCount = isMobile
    ? Number(!!addressId) + Number(!!service.length) + Number(!!status.length) + Number(!!startDate || !!endDate)
    : Number(!!addressId) + Number(!!service.length) + Number(!!status.length)

  return (
    <>
      <AppBar
        position="sticky"
        color="inherit"
        style={{ margin: '-1.5rem -1.5rem 1.5rem', top: isMobile ? '56px' : '4rem', width: 'auto' }}
        elevation={1}
      >
        <Toolbar style={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="Создать новый заказ">
            <IconButton size="small" onClick={handleCreate}>
              <CreateIcon />
            </IconButton>
          </Tooltip>
          {!isMobile && (
            <Box display="flex" gap="0.5rem" alignItems="center" minWidth={'260px'}>
              <DatePicker placeholder="С" allowClear value={startDate || null} onChange={handleStartDateChange} />
              {' - '}
              <DatePicker placeholder="По" allowClear value={endDate || null} onChange={handleEndDateChange} />
            </Box>
          )}
          <SearchInput value={term} onChange={handleTermChange} onClear={() => setTerm('')} withoutLabel={true} />
          <Tooltip title="Фильтры">
            <Badge badgeContent={filterCount} color="primary">
              <IconButton onClick={openFilters} size="small">
                <FiltersIcon />
              </IconButton>
            </Badge>
          </Tooltip>
          <Tooltip title="Экспортировать заказы в файл Excel">
            <a
              href={adminService.order.getExportDataLink({
                startDate,
                endDate,
                term,
                addressId,
                status: statusForRequest,
                service
              })}
              target="_blank"
            >
              <IconButton>
                <CloudDownloadIcon fontSize="small" />
              </IconButton>
            </a>
          </Tooltip>
          <Menu open={!!filtersMenu} anchorEl={filtersMenu} onClose={closeFilters} elevation={2}>
            <div>
              {!!isMobile && (
                <>
                  <FilterMenuWrapper style={{ marginBottom: '0.5rem' }}>
                    <Box display="flex" gap="0.5rem" alignItems="center" minWidth={'260px'}>
                      <DatePicker
                        placeholder="С"
                        allowClear
                        value={startDate || null}
                        onChange={handleStartDateChange}
                      />
                      {' - '}
                      <DatePicker placeholder="По" allowClear value={endDate || null} onChange={handleEndDateChange} />
                    </Box>
                  </FilterMenuWrapper>
                  <Divider />
                </>
              )}
              <FilterMenuWrapper>
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink>Адрес</InputLabel>
                  <Select
                    value={!!addressId && !Number.isNaN(addressId) ? addressId : -1}
                    onChange={(ev) => {
                      if (ev.target.value !== '-1') {
                        setAddressId(String(ev.target.value))
                        setService((s) => {
                          const availableServices =
                            servicesData?.data.filter((el) => String(el.addressId) === String(ev.target.value)) || []

                          const selectedServices = s.filter((el) =>
                            availableServices.map((s) => String(s.id)).includes(el)
                          )

                          return selectedServices
                        })
                      } else {
                        setAddressId(undefined)
                      }
                      setPage(1)
                    }}
                  >
                    <MenuItem value="-1">Все адреса</MenuItem>
                    {addressData?.data?.map?.((address) => (
                      <MenuItem key={address.id} value={String(address.id)}>
                        {address.address}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink>Услуга</InputLabel>
                  <Select
                    multiple
                    value={service}
                    onChange={(ev) => {
                      if (ev.target.value?.length > 0) {
                        setService(ev.target.value as string[])
                      } else {
                        setService([])
                      }
                      setPage(1)
                    }}
                  >
                    {servicesData?.data
                      ?.filter((s) => !!s.withReservation)
                      ?.filter((s) => !addressId || String(s.addressId) === String(addressId))
                      ?.map?.((service) => (
                        <MenuItem key={service.id} value={String(service.id)}>
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {!cancelledOnly && (
                  <FormControl fullWidth variant="standard">
                    <InputLabel shrink>Статус</InputLabel>
                    <Select
                      multiple
                      value={status}
                      onChange={(ev) => {
                        if (ev.target.value?.length > 0) {
                          setStatus(ev.target.value as OrderStatus[])
                        } else {
                          setStatus([])
                        }
                        setPage(1)
                      }}
                    >
                      <MenuItem key="Created" value={'Created'}>
                        Создан
                      </MenuItem>
                      <MenuItem key="Confirmed" value={'Confirmed'}>
                        Подтвержден
                      </MenuItem>
                      <MenuItem key="Completed" value={'Completed'}>
                        Завершен
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    disabled={!addressId && !service.length && !status.length && !term && !startDate && !endDate}
                    onClick={handleClearFilters}
                  >
                    Сбросить
                  </Button>
                </div>
              </FilterMenuWrapper>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" style={{ padding: isMobile ? 0 : undefined }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 1, mb: 2 }}>
          <Typography color="GrayText" variant="body2">
            {data?.data?.list?.length || 0} из {data?.data?.total || 0} результатов
          </Typography>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden', display: 'grid' }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '72px' }}></TableCell>
                  <TableCell style={{ minWidth: '180px' }}>Клиент</TableCell>
                  <TableCell style={{ width: '200px' }}>Услуги</TableCell>
                  <TableCell style={{ width: '100px' }}>Бронь</TableCell>
                  <TableCell style={{ width: '80px' }}>Итого</TableCell>
                  <TableCell style={{ width: '100px' }}>Оплата</TableCell>
                  <TableCell style={{ width: '120px' }}>Статус</TableCell>
                  <TableCell style={{ width: '50px' }}></TableCell>
                </TableRow>
              </TableHead>
              {(!!isLoading || !!addressIsLoading || !!servicesIsLoading) && (
                <TableBody>
                  {[...Array(7)].map((_, i) => (
                    <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      {[...Array(8)].map((_, index) => (
                        <TableCell key={index}>
                          <Skeleton />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              )}
              {!isLoading && !addressIsLoading && !servicesIsLoading && (
                <TableBody>
                  {!data?.data?.list?.length && (
                    <TableRow>
                      <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                        Ничего не найдено
                      </TableCell>
                    </TableRow>
                  )}
                  {data?.data?.list?.map?.((order) => {
                    const serviceWithReservation = order.services.find((s) => !!s.reservation)

                    return (
                      <Fragment key={order.id}>
                        {order.staffComment && (
                          <TableRow sx={{ '& td, & th': { border: 0 } }}>
                            <TableCell />
                            <TableCell colSpan={7} style={{ padding: '0.5rem 0.5rem 0' }}>
                              <Chip label={order.staffComment} size="small" variant="outlined" />
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow
                          key={order.id}
                          sx={{
                            borderTop: 'none',
                            '&:last-child td, &:last-child th': { border: 0 },
                            '& td': order.staffComment ? { padding: '0.5rem 1rem 1rem' } : undefined
                          }}
                        >
                          <TableCell
                            style={{
                              verticalAlign: 'top',
                              width: '72px',
                              maxWidth: '72px'
                            }}
                          >
                            <div style={{ fontSize: '12px', opacity: 0.64 }}>
                              {Intl.DateTimeFormat('ru', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                              }).format(order.dateTime)}
                            </div>
                            <div style={{ fontSize: '13px', opacity: 0.64 }}>
                              {Intl.DateTimeFormat('ru', {
                                hour: '2-digit',
                                minute: '2-digit'
                              }).format(order.dateTime)}
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              width: '180px',
                              minWidth: '180px',
                              verticalAlign: 'top',
                              wordBreak: 'break-word'
                            }}
                          >
                            <div>{order.customer?.name}</div>
                            <div>
                              <MuiLink style={{ fontSize: '13px' }} href={`tel:${order.customer?.phoneNumber}`}>
                                {order.customer?.phoneNumber}
                              </MuiLink>
                            </div>
                            <div>
                              <MuiLink style={{ fontSize: '11px' }} href={`mailto:${order.customer?.email}`}>
                                {order.customer?.email}
                              </MuiLink>
                            </div>
                            <Typography
                              color="GrayText"
                              variant="body2"
                              style={{ fontSize: '13px', marginTop: '0.25rem' }}
                            >
                              {order.comment}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ verticalAlign: 'top', width: '200px', maxWidth: '200px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.125rem' }}>
                              {order.services
                                .sort(
                                  (a, b) => Number(!!b.service.withReservation) - Number(!!a.service.withReservation)
                                )
                                .map((s) => (
                                  <div
                                    key={s.id}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '0.5rem',
                                      opacity: s?.service?.withReservation ? 1 : 0.64,
                                      fontSize: s?.service?.withReservation ? '14px' : '12px'
                                    }}
                                  >
                                    <div
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        maxWidth: '250px',
                                        flex: 1
                                      }}
                                    >
                                      {s?.service?.withReservation ? '' : '+ '}
                                      {s.service.name}
                                    </div>
                                    <div style={{ fontSize: '12px', opacity: 0.86 }}>{s?.price} ₽</div>
                                  </div>
                                ))}
                            </div>
                          </TableCell>
                          <TableCell style={{ verticalAlign: 'top', width: '100px', maxWidth: '100px' }}>
                            {!!serviceWithReservation?.reservation && (
                              <div>
                                <div style={{ fontSize: '14px', fontWeight: 500 }}>
                                  {Intl.DateTimeFormat('ru', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit'
                                  }).format(serviceWithReservation.reservation.startTime)}
                                </div>
                                <div style={{ fontSize: '12px' }}>
                                  {[
                                    Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(
                                      serviceWithReservation.reservation.startTime
                                    ),
                                    Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(
                                      serviceWithReservation.reservation.endTime
                                    )
                                  ].join(' - ')}
                                </div>
                                <div style={{ fontSize: '12px', marginTop: '0.25rem', opacity: 0.56 }}>
                                  {serviceWithReservation.reservation.persons}{' '}
                                  {pluralizationRu(serviceWithReservation.reservation.persons || 1, [
                                    'человек',
                                    'человека',
                                    'человек'
                                  ])}
                                </div>
                              </div>
                            )}
                          </TableCell>
                          <TableCell style={{ verticalAlign: 'top', width: '80px', maxWidth: '80px' }}>
                            <Typography variant="body2">{order.total} ₽</Typography>
                          </TableCell>
                          <TableCell style={{ verticalAlign: 'top', width: '110px', maxWidth: '110px' }}>
                            <div>{paymentStatusText[order.paymentStatus]}</div>
                            {!!order.prePaymentAmount && (
                              <Typography variant="caption" color="#4e4e4e">
                                Внесено: <span style={{ color: '#5567f5' }}>{order.prePaymentAmount} ₽</span>
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell style={{ verticalAlign: 'top', width: '120px', maxWidth: '120px' }}>
                            {!!order.status && (
                              <Chip
                                label={orderStatusText[order.status]}
                                size="small"
                                style={{
                                  backgroundColor: orderStatusBackground[order.status],
                                  color: orderStatusColor[order.status]
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell style={{ verticalAlign: 'top', width: '50px', maxWidth: '50px' }}>
                            <Tooltip title="Просмотреть информацию о заказе">
                              <NavLink to={orderView(order.id)}>
                                <IconButton size="small">
                                  <ViewIcon fontSize="small" />
                                </IconButton>
                              </NavLink>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
        {!isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Pagination count={pageCount} page={page} onChange={(_, page) => handlePageChange(page)} color="primary" />
          </Box>
        )}
      </Container>
      <Dialog open={orderCreateDialogOpen} onClose={closeOrderCreateDialog} fullWidth>
        <DialogTitle>Создание заказа</DialogTitle>
        <OrderEditForm onClose={closeOrderCreateDialog} />
      </Dialog>
    </>
  )
}

export { OrderList }
