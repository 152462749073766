import { useMutation, useQuery, useQueryClient } from 'react-query'
import { adminService } from 'shared/api'
import { ServicePriceListProps } from './types'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import TimeIcon from '@mui/icons-material/AccessTime'
import DateIcon from '@mui/icons-material/Event'
import AddIcon from '@mui/icons-material/Add'
import ExportIcon from '@mui/icons-material/CloudDownload'
import ImportIcon from '@mui/icons-material/CloudUpload'
import { Fragment, useState } from 'react'
import { PriceEdit } from './service-price-edit'
import { ServicePriceExportModel, ServicePriceCreateModel, ServicePriceModel } from 'shared/models'
import { nativeFilesSelect } from 'shared/utils/file'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { useAppDispatch } from 'store'

function ServicePriceList({ serviceId }: ServicePriceListProps) {
  const queryClient = useQueryClient()

  const dispatch = useAppDispatch()

  const { data, isLoading } = useQuery(
    ['servicePriceList', serviceId],
    ({ signal }) => adminService.service.getServicePriceList(Number(serviceId), { signal }),
    { enabled: !!serviceId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { mutateAsync: createPrice, isLoading: isCreateLoading } = useMutation((data: ServicePriceCreateModel) =>
    adminService.service.createServicePrice(data)
  )
  const { mutateAsync: deletePrice, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.service.deleteServicePrice(id)
  )

  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [editPriceId, setEditPriceId] = useState<null | number>(null)

  function handlePriceEditClose() {
    setEditModalIsOpen(false)
    queryClient.invalidateQueries(['servicePriceList', serviceId])
  }

  function handleExport() {
    if (!data) {
      return
    }

    const prices: ServicePriceModel[] = data.data || []
    const pricesForExport: ServicePriceExportModel[] = prices.map((price) => ({
      name: price.name,
      prices: price.prices,
      schedule: price.schedule
    }))

    const json = JSON.stringify(pricesForExport)

    const blob = new Blob([json], { type: 'application/json' })
    const href = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = href
    link.download = `prices_${serviceId}_${new Date().toISOString()}.json`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  async function handleImport() {
    try {
      const fileRaw = await nativeFilesSelect({ multiple: false, accept: '.json' })
      const resText = await fileRaw.text()
      const prices = JSON.parse(resText) as ServicePriceExportModel[]

      if (!!prices?.length && !!prices.filter((p) => !!p.prices).length) {
        const pricesForCreate: ServicePriceCreateModel[] = prices.map((p) => ({
          name: p.name || '',
          prices: p.prices,
          schedule: p.schedule,
          serviceId: serviceId
        }))

        const currentPrices: ServicePriceModel[] = data?.data || []

        for (const p of currentPrices) {
          await deletePrice(p.id)
        }

        for (const p of pricesForCreate) {
          await createPrice(p)
        }

        queryClient.invalidateQueries(['servicePriceList', serviceId])
      } else {
        dispatch(showMessage({ text: 'Нет доступных для импорта прайс листов', type: MessageTypes.Error }))
      }
    } catch (e) {
      dispatch(showMessage({ text: 'Не удалось импортировать файл', type: MessageTypes.Error }))
    }
  }

  return (
    <>
      {(!!isLoading || !!isCreateLoading || !!isDeleteLoading) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && !isCreateLoading && !isDeleteLoading && (
        <>
          <Box
            padding={1}
            sx={{
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-end',
              borderBottom: '1px solid',
              borderColor: 'divider'
            }}
          >
            <Button startIcon={<ImportIcon />} onClick={handleImport}>
              Импортировать
            </Button>
            <Button startIcon={<ExportIcon />} onClick={handleExport}>
              Экспортировать
            </Button>
          </Box>
          <List disablePadding>
            {data?.data?.map?.((price) => (
              <Fragment key={price.id}>
                <div style={{ marginBottom: '1rem' }}>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{ fontWeight: 500 }}
                      primary={price.name}
                      secondary={price.prices
                        .map((p) =>
                          [p.numberOfPersons ? `${p.numberOfPersons} чел.` : undefined, `${p.price} руб.`]
                            .filter(Boolean)
                            .join(' - ')
                        )
                        .join(', ')}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          setEditPriceId(price.id)
                          setEditModalIsOpen(true)
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', padding: '0 1rem' }}>
                    {price.schedule?.map((schedule, i) => (
                      <div
                        key={`schedule-${i}`}
                        style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem', padding: '0.25rem 0' }}
                      >
                        <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                          {schedule.date ? (
                            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                              <DateIcon fontSize="small" />
                              <Typography variant="body2">
                                {Intl.DateTimeFormat('ru', { timeZone: 'UTC' }).format(schedule.date)}
                              </Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', gap: '0.25rem', marginBottom: '0.25rem' }}>
                              {schedule.weekdays
                                ?.map((w) => ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'][w])
                                .map((w, wi) => (
                                  <Chip size="small" color="primary" label={w} key={`schedule-${i}-weekday-${wi}`} />
                                ))}
                            </Box>
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                          <TimeIcon fontSize="small" />
                          <Typography variant="body2">
                            {[
                              Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(
                                schedule.startTime
                              ),
                              Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(schedule.endTime)
                            ].join(' - ')}
                          </Typography>
                        </Box>
                      </div>
                    ))}
                  </div>
                </div>
                <Divider />
              </Fragment>
            ))}
            <Box style={{ display: 'flex', justifyContent: 'center', margin: '0.5rem 1rem' }}>
              <IconButton
                onClick={() => {
                  setEditPriceId(null)
                  setEditModalIsOpen(true)
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </List>
        </>
      )}
      <Dialog open={editModalIsOpen} onClose={() => setEditModalIsOpen(false)} fullWidth>
        <PriceEdit
          key={editPriceId || undefined}
          onClose={handlePriceEditClose}
          priceId={editPriceId || undefined}
          serviceId={serviceId}
        />
      </Dialog>
    </>
  )
}

export { ServicePriceList }
