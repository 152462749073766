import { Button, Card, Container, TextField, Typography } from '@mui/material'
import { useAppDispatch } from 'store'
import { setUserProfile } from 'store/slices/profile-slice'
import { adminService } from 'shared/api'
import { Controller, useForm } from 'react-hook-form'
import { useState } from 'react'
import { login } from 'shared/auth'

type LoginForm = { email: string; password: string }

function LoginPage() {
  const { control, handleSubmit } = useForm<LoginForm>({ defaultValues: { email: '', password: '' } })

  const [isFetching, setIsFetching] = useState(false)

  const dispatch = useAppDispatch()

  async function onSubmit(data: LoginForm) {
    setIsFetching(true)

    try {
      await login(data.email, data.password)

      const profile = await adminService.user.getCurrentUserProfile()

      dispatch(setUserProfile(profile.data))
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Container maxWidth="xs">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card style={{ padding: '2rem' }}>
            <Typography textAlign="center" variant="h3" sx={{ mb: 6, mt: 3 }}>
              inside!quest
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Email"
                    label="Email"
                    type="email"
                    autoComplete="email"
                    value={field.value}
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disabled={isFetching}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    required
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    placeholder="Пароль"
                    label="Пароль"
                    type="password"
                    autoComplete="current-password"
                    value={field.value}
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disabled={isFetching}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    required
                  />
                )}
              />
              <Button type="submit" disabled={isFetching} variant="contained" sx={{ mt: 4, mb: 2 }} disableElevation>
                Войти
              </Button>
            </div>
          </Card>
        </form>
      </Container>
    </div>
  )
}

export { LoginPage }
