import React, { useState } from 'react'
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { logout } from 'shared/auth'
import { useAppDispatch, useAppSelector } from 'store'
import {
  clearProfile,
  getIsSettingsModeSelector,
  getUserProfileSelector,
  setSettingsMode
} from 'store/slices/profile-slice'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Logout from '@mui/icons-material/Logout'
import UsersIcon from '@mui/icons-material/Person'
import PlaceIcon from '@mui/icons-material/Place'
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy'
import TagIcon from '@mui/icons-material/Tag'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import ReservationIcon from '@mui/icons-material/Event'
import ClintsIcon from '@mui/icons-material/People'
import {
  addressList,
  questCategoryList,
  questList,
  serviceList,
  userList,
  profile as profileRoute,
  orderList,
  reservationScheduler,
  clientList,
  pageList,
  mainSettings,
  cancelledOrderList,
  reservationWeekdaysScheduler
} from 'pages/nav'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import SystemModeIcon from '@mui/icons-material/SettingsBrightness'
import { ThemeMode, useMode } from 'app/providers/theme-provider'
import TuneIcon from '@mui/icons-material/Tune'
import GroupsIcon from '@mui/icons-material/Groups'
import PageIcon from '@mui/icons-material/Description'
import SettingsIcon from '@mui/icons-material/Settings'
import OrdersIcon from '@mui/icons-material/PlaylistAddCheck'
import CancelledOrdersIcon from '@mui/icons-material/PlaylistRemove'
import DateRangeIcon from '@mui/icons-material/DateRange'
import ExpandLess from '@mui/icons-material/KeyboardArrowLeft'
import ExpandMore from '@mui/icons-material/KeyboardArrowRight'
import MenuIcon from '@mui/icons-material/Menu'
import { getMenuOpenSelector, handleMenu } from 'store/slices/menu-slice'
import { closedMixin, drawerWidth, openedMixin } from './styled'
import { useMediaQuery } from 'react-responsive'

const DesktopDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    { props: ({ open }) => open, style: { ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme) } },
    { props: ({ open }) => !open, style: { ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme) } }
  ]
}))

function MainLayout() {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const profile = useAppSelector(getUserProfileSelector)
  const isSettingsMode = useAppSelector(getIsSettingsModeSelector)

  const location = useLocation()

  const { mode, setMode } = useMode()

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)

  const open = useAppSelector(getMenuOpenSelector)

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const isAdmin = profile?.profile === 'Admin'

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  function handleLogOut() {
    logout()
    dispatch(clearProfile())
  }

  function handleModeChange(_, value: ThemeMode) {
    setMode(value)
  }

  function handleProfileClick() {
    handleCloseUserMenu()
    navigate(profileRoute())
  }

  function handleSettingsMode() {
    dispatch(setSettingsMode(true))
  }

  function handleViweMode() {
    dispatch(setSettingsMode(false))
  }

  const adminMenuitems = [
    {
      id: 'questList',
      name: 'Квесты',
      icon: <TheaterComedyIcon />,
      path: questList()
    },
    {
      id: 'categoryList',
      name: 'Категории',
      icon: <TagIcon />,
      path: questCategoryList()
    },
    {
      id: 'serviceList',
      name: 'Услуги',
      icon: <ShoppingBasketIcon />,
      path: serviceList()
    },
    {
      id: 'addressList',
      name: 'Адреса',
      icon: <PlaceIcon />,
      path: addressList()
    },
    {
      id: 'userList',
      name: 'Сотрудники',
      icon: <UsersIcon />,
      path: userList()
    },
    {
      id: 'pageList',
      name: 'Страницы',
      icon: <PageIcon />,
      path: pageList()
    },
    {
      id: 'parameters',
      name: 'Параметры',
      icon: <SettingsIcon />,
      path: mainSettings()
    }
  ]

  const crmMenuitems = [
    {
      id: 'orderList',
      name: 'Заказы',
      icon: <OrdersIcon />,
      path: orderList()
    },
    {
      id: 'cancelledOrderList',
      name: 'Отмененные заказы',
      icon: <CancelledOrdersIcon />,
      path: cancelledOrderList()
    },
    {
      id: 'reservationScheduler',
      name: 'Планировщик бронирований',
      icon: <ReservationIcon />,
      path: reservationScheduler()
    },
    {
      id: 'reservationWeekdaysScheduler',
      name: 'Недельный планировщик',
      icon: <DateRangeIcon />,
      path: reservationWeekdaysScheduler()
    },
    {
      id: 'clientList',
      name: 'Клиенты',
      icon: <ClintsIcon />,
      path: clientList()
    }
  ]

  const menuItems = !!isSettingsMode && !!isAdmin ? adminMenuitems : crmMenuitems

  return (
    <>
      <AppBar position="sticky" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} elevation={1}>
        <div style={{ padding: '0 2rem' }}>
          <Toolbar disableGutters>
            {!!isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => setMobileDrawerOpen(!mobileDrawerOpen)}
                sx={{ mr: 2, ml: -2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" lineHeight="16px">
                inside!quest
              </Typography>
              <Typography fontSize="10px" marginTop="4px" marginBottom="-8px" fontWeight={500}>
                v.{__APP_VERSION__}
              </Typography>
            </Box>
            <Box>
              <IconButton size="large" onClick={handleOpenUserMenu} color="inherit">
                <AccountCircle />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorElUser}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!anchorElUser}
                onClose={handleCloseUserMenu}
                elevation={2}
              >
                {profile && (
                  <MenuItem onClick={handleProfileClick} sx={{ gap: 2 }}>
                    <AccountCircle fontSize="large" />
                    <div>
                      <Typography>
                        {profile.lastName} {profile.firstName}
                      </Typography>
                      <Typography variant="body2" style={{ opacity: 0.72 }}>
                        {profile.email}
                      </Typography>
                    </div>
                  </MenuItem>
                )}
                <Divider />
                <div style={{ padding: '0 0.5rem 0.5rem' }}>
                  <ToggleButtonGroup value={mode} exclusive size="small" fullWidth onChange={handleModeChange}>
                    <ToggleButton value="light">
                      <LightModeIcon fontSize="small" />
                    </ToggleButton>
                    <ToggleButton value="system">
                      <SystemModeIcon fontSize="small" />
                    </ToggleButton>
                    <ToggleButton value="dark">
                      <DarkModeIcon fontSize="small" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <Divider style={{ marginBottom: '0.5rem' }} />
                {!isSettingsMode && !!isAdmin && (
                  <MenuItem onClick={handleSettingsMode}>
                    <ListItemIcon>
                      <TuneIcon fontSize="small" />
                    </ListItemIcon>
                    Администрирование
                  </MenuItem>
                )}
                {!!isSettingsMode && (
                  <MenuItem onClick={handleViweMode}>
                    <ListItemIcon>
                      <GroupsIcon fontSize="small" />
                    </ListItemIcon>
                    CRM
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogOut}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Выход
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </div>
      </AppBar>
      <Box sx={{ display: 'flex' }}>
        {!isMobile && (
          <DesktopDrawer variant="permanent" open={open}>
            <Toolbar />
            <Box sx={{ flex: 1 }}>
              <List>
                {menuItems.map(({ id, name, icon, path }) => (
                  <Link to={path} key={id} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={[
                          { minHeight: 48, px: 2.5 },
                          open ? { justifyContent: 'initial' } : { justifyContent: 'center' }
                        ]}
                        selected={location.pathname.includes(path)}
                      >
                        <ListItemIcon
                          sx={[
                            { minWidth: 0, justifyContent: 'center', transition: 'all 0.16s ease-in-out' },
                            open ? { mr: 3 } : { mr: 'auto' }
                          ]}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText
                          sx={[open ? { opacity: 1, width: '220px', textWrap: 'wrap' } : { opacity: 0 }]}
                          primary={name}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Box>
            <Divider />
            <List>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={[{ minHeight: 48, px: 2.5 }, open ? { justifyContent: 'initial' } : { justifyContent: 'center' }]}
                  onClick={() => {
                    dispatch(handleMenu())
                  }}
                >
                  <ListItemIcon
                    sx={[
                      { minWidth: 0, justifyContent: 'center', transition: 'all 0.16s ease-in-out' },
                      open ? { mr: 3 } : { mr: 'auto' }
                    ]}
                  >
                    {open ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                  </ListItemIcon>
                  <ListItemText sx={[open ? { opacity: 1 } : { opacity: 0 }]} primary="Свернуть" />
                </ListItemButton>
              </ListItem>
            </List>
          </DesktopDrawer>
        )}
        {!!isMobile && (
          <MuiDrawer open={mobileDrawerOpen} onClose={() => setMobileDrawerOpen(false)}>
            <Toolbar />
            <Box sx={{ flex: 1 }}>
              <List>
                {menuItems.map(({ id, name, icon, path }) => (
                  <Link to={path} key={id} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={[{ minHeight: 48, px: 2.5 }, { justifyContent: 'initial' }]}
                        selected={location.pathname.includes(path)}
                      >
                        <ListItemIcon
                          sx={[
                            { minWidth: 0, justifyContent: 'center', transition: 'all 0.16s ease-in-out' },
                            { mr: 3 }
                          ]}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText sx={{ width: '220px', textWrap: 'wrap' }} primary={name} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Box>
          </MuiDrawer>
        )}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  )
}

export { MainLayout }
